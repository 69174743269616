// import { google } from 'googleapis';
import React, { useState } from "react";
import { Button, CloseButton, Form, Modal } from "react-bootstrap";
import styles from "./NavbarComponent/NavbarComponent.module.scss";
import axios from "axios";
import { sendEmail } from "../emailjs";


export default function InterestedBtn() {
  const [interestedName, setInterestedName] = useState("");
  const [interestedEmail, setInterestedEmail] = useState("");
  const [interestedContact, setInterestedContact] = useState("");
  const [interestedCountry, setInterestedCountry] = useState("");
  const [interestedText, setInterestedText] = useState("");
  const [interestedBusinessType, setInterestedBusinessType] =
    useState("Individual");
  const [interestedBusinessName, setInterestedBusinessName] = useState("");
  const [interestedMeetType, setInterestedMeetType] = useState("Physical");
  const [interestedJobType, setInterestedJobType] = useState("Builder");
  const [showInterestedModal, setShowInterestedModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const appendSpreadsheet = async (data) => {
    axios.post("https://sheet2api.com/v1/8DiE5jMBTMqM/soaron-intrested", data)
      .then((res) => {
        setSubmitting(false);
        setShowInterestedModal(false);
        setInterestedBusinessName("");
        setInterestedBusinessType("Individual");
        setInterestedContact("");
        setInterestedCountry("");
        setInterestedEmail("");
        setInterestedJobType("Builder");
        setInterestedMeetType("Physical");
        setInterestedName("");
        setInterestedText("");
        sendEmail({
          subject: 'Colorown Interested',
          message: 'A new visitor has shown interest in Colorown!',
          link: 'https://docs.google.com/spreadsheets/d/1geduOomT2lClipMnv34JH3lcE3cENgojzaDskYDs3O0/edit#gid=0'
        })

      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        alert("Form submission is closed for now. Please try again later.");
        return;
      })
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    const data = {
      name: interestedName,
      email: interestedEmail,
      contact: interestedContact,
      country: interestedCountry,
      text: interestedText,
      businessType: interestedBusinessType,
      businessName: interestedBusinessName,
      meetType: interestedMeetType,
      jobType: interestedJobType,
    };

    appendSpreadsheet(data);
  };

  return (
    <>
      <button
        className={styles.interestedButton}
        onClick={() => setShowInterestedModal(true)}
      >
        Interested?
      </button>
      <Modal
        show={showInterestedModal}
        onHide={() => setShowInterestedModal(false)}
        dialogClassName={styles.interestedModalDialog}
        contentClassName={styles.interestedModalContent}
        className="p-0"
      >
        <Modal.Body className="px-3 px-md-5 py-0">
          <div className="d-flex justify-content-between align-items-center my-4 py-2">
            <span style={{ color: "#7A839E", fontSize: "15px" }}>
              Please fill the form if you are interested
            </span>
            <CloseButton onClick={() => setShowInterestedModal(false)} />
          </div>

          <Form
            className="d-flex flex-column gap-4 mt-3"
            onSubmit={handleSubmit}
          >
            <div className="d-flex flex-column flex-md-row gap-4">
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className={styles.formControl}
                  type={"text"}
                  value={interestedName}
                  onChange={(event) => setInterestedName(event.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className={styles.formControl}
                  type={"email"}
                  value={interestedEmail}
                  onChange={(event) => setInterestedEmail(event.target.value)}
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex flex-column flex-md-row gap-4">
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>
                  Contact Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className={styles.formControl}
                  type={"tel"}
                  value={interestedContact}
                  onChange={(event) => setInterestedContact(event.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className={styles.formGroup}>
                <Form.Label className={styles.formLabel}>
                  Country <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className={styles.formControl}
                  type={"country"}
                  value={interestedCountry}
                  onChange={(event) => setInterestedCountry(event.target.value)}
                  required
                />
              </Form.Group>
            </div>

            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>
                Why are you interested? <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as={"textarea"}
                rows={3}
                style={{ resize: "none" }}
                className={styles.formControl}
                type={"text"}
                value={interestedText}
                onChange={(event) => setInterestedText(event.target.value)}
                required
              />
            </Form.Group>

            <div>
              <span className={styles.formLabel}>
                Are you an <span className="text-danger">*</span>
              </span>

              <div className="d-flex gap-md-5 gap-0 mt-4">
                <Form.Check className="d-flex gap-3 mx-3 align-items-center">
                  <Form.Check.Input
                    type={"radio"}
                    defaultChecked={interestedBusinessType === "Individual"}
                    onClick={() => setInterestedBusinessType("Individual")}
                  />
                  <Form.Check.Label
                    className={styles.formLabel}
                    style={{ fontSize: "14px" }}
                  >
                    Individual
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="d-flex gap-3 mx-3 align-items-center">
                  <Form.Check.Input
                    type={"radio"}
                    defaultChecked={interestedBusinessType === "Business"}
                    onClick={() => setInterestedBusinessType("Business")}
                  />
                  <Form.Check.Label
                    className={styles.formLabel}
                    style={{ fontSize: "14px" }}
                  >
                    Business
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>

            <Form.Group className={styles.formGroup}>
              <Form.Label className={styles.formLabel}>
                Business Name (if applicable)
              </Form.Label>
              <Form.Control
                className={styles.formControl}
                type={"text"}
                value={interestedBusinessName}
                onChange={(event) =>
                  setInterestedBusinessName(event.target.value)
                }
              />
            </Form.Group>

            <div>
              <span className={styles.formLabel}>
                How do you want to see the prototype and meet the team{" "}
                <span className="text-danger">*</span>
              </span>

              <div className="d-flex gap-md-5 gap-0 mt-4">
                <Form.Group className="d-flex gap-3 mx-3">
                  <Form.Check
                    type={"radio"}
                    style={{ color: "black" }}
                    defaultChecked={interestedMeetType === "Physical"}
                    onClick={() => setInterestedMeetType("Physical")}
                  />
                  <Form.Label
                    className={styles.formLabel}
                    style={{ fontSize: "14px" }}
                  >
                    Physical
                  </Form.Label>
                </Form.Group>
                <Form.Group className="d-flex gap-3 mx-3">
                  <Form.Check
                    type={"radio"}
                    defaultChecked={interestedMeetType === "Digital"}
                    onClick={() => setInterestedMeetType("Digital")}
                  />
                  <Form.Label
                    className={styles.formLabel}
                    style={{ fontSize: "14px" }}
                  >
                    Digital
                  </Form.Label>
                </Form.Group>
              </div>
            </div>

            <div>
              <span className={styles.formLabel}>
                Are you a <span className="text-danger">*</span>
              </span>

              <div className="d-flex flex-column flex-md-row gap-md-5 gap-3 mt-4">
                <div className="d-flex justify-content-between">
                  <Form.Group className="d-flex gap-3 mx-3">
                    <Form.Check
                      type={"radio"}
                      defaultChecked={interestedJobType === "Builder"}
                      onClick={() => setInterestedJobType("Builder")}
                    />
                    <Form.Label
                      className={styles.formLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Builder
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="d-flex gap-3 mx-3">
                    <Form.Check
                      type={"radio"}
                      defaultChecked={interestedJobType === "Painter"}
                      onClick={() => setInterestedJobType("Painter")}
                    />
                    <Form.Label
                      className={styles.formLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Painter
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-between">
                  <Form.Group className="d-flex gap-3 mx-3">
                    <Form.Check
                      type={"radio"}
                      defaultChecked={interestedJobType === "Contractor"}
                      onClick={() => setInterestedJobType("Contractor")}
                    />
                    <Form.Label
                      className={styles.formLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Contractor
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="d-flex gap-3 mx-3">
                    <Form.Check
                      type={"radio"}
                      defaultChecked={interestedJobType === "Other"}
                      onClick={() => setInterestedJobType("Other")}
                    />
                    <Form.Label
                      className={styles.formLabel}
                      style={{ fontSize: "14px" }}
                    >
                      Other
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </div>

            <Button
              type={"submit"}
              variant={"dark"}
              className="py-2 my-3"
              style={{ borderRadius: 14 }}
              disabled={submitting}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Form>
          <br />
        </Modal.Body>
      </Modal>
    </>
  );
}
